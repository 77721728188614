import apiClient from '@/services/axios'

export const getExaminations = async (query) => {
  const res = await apiClient.get(`/api/pemeriksaan`)
  if (res) {
    return res
  } else {
    return null
  }
}
export const getExaminationById = async (id) => {
  const res = await apiClient.get(`/api/pemeriksaan/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}

export const getExaminationsCategories = async (query) => {
  const res = await apiClient.get(`/api/pemeriksaan/categories`)
  if (res) {
    return res
  } else {
    return null
  }
}

export const getLoincCodeAndAnswer = async (query) => {
  const res = await apiClient.get(`/api/pemeriksaan/loincCode`)
  if (res) {
    return res
  } else {
    return null
  }
}

export const createExamination = async (master, components) => {
  const res = await apiClient.post(`/api/pemeriksaan`, { master, components })
  if (res) {
    return res
  } else {
    return null
  }
}
