<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="">
          <div class="card-body">
            <div class="row" style="background-color: #f3fdff">
              <div class="col-3 container">
                <img
                  src="resources/images/medisync-big.svg"
                  alt="Medisync"
                  height="50px"
                />
              </div>
              <div class="col-6 header-text container">
                Master Pemeriksaan Laboratorium
              </div>
              <div class="col-3 container">
                {{ this.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row px-4 mb-4">
        <div class="col-2 p-0 pr-3">
          <a-button
            type="primary"
            class="w-100"
            style="height: 31.5px"
            @click="handleAddExamination"
          >
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="resources/images/plus-icon2.svg"
                alt="Icon"
                style="height: 16px; width: 16px"
              />
              <span class="ml-1">Tambah Pemeriksaan</span>
            </div>
          </a-button>
        </div>
        <div class="col-4 p-0 pr-3">
          <a-select
            ref="select"
            style="width: 100%"
            size="medium"
            class="customSelect"
            v-model="selectedCategory"
            @change="handleChangeCategories"
          >
            <a-select-option value="" key="" selected>
              <div class="d-flex align-items-center">
                <img
                  src="resources/images/filter-icon.svg"
                  alt="Icon"
                  style="height: 16px; width: 16px"
                />
                <span class="ml-2">Pilih Kategori Lab</span>
              </div>
            </a-select-option>
            <a-select-option
              v-for="(category, idx) in categories"
              :value="category.name"
              :key="idx"
              >{{ category.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="col-6 p-0">
          <a-input
            placeholder="Filter by Nama Pemeriksaan"
            @change="filterExamination"
            style="height: 31.5px"
          />
        </div>
      </div>

      <a-table
        :dataSource="filteredDataSource"
        :columns="columns"
        @rowClick="handleRowClick"
      />
    </div>
  </div>
</template>

<script>
import {
  getExaminations,
  getExaminationsCategories,
} from '@/services/axios/api/pemeriksaan'

export default {
  data: function () {
    const columns = [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'no',
        width: '50px',
      },
      {
        title: 'Kategori Lab',
        dataIndex: 'ExaminationCategory.name',
        key: 'ExaminationCategory.name',
        width: '200px',
      },
      {
        title: 'Nama Pemeriksaan',
        dataIndex: 'name',
        key: 'id',
      },
    ]
    return {
      example: [],
      name: '',
      filterText: '', // State for the filter input
      dataSource: [],
      filteredDataSource: [],
      columns,
      categories: [],
      selectedCategory: '',
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },

  methods: {
    getName() {
      const name = localStorage.getItem('name')
      this.name = name
    },
    async fetchData() {
      const { data } = await getExaminations()
      const { data: dataCategories } = await getExaminationsCategories()
      this.categories = dataCategories.categories
      console.log(dataCategories, 'inicategories')
      const dataTable = data.pemeriksaan.map((e, idx) => {
        return {
          ...e,
          key: idx + 1,
        }
      })
      this.dataSource = dataTable
      this.filteredDataSource = dataTable
    },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase() // Convert to lowercase for case-insensitive matching
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeCategories(e) {
      if (e) {
        this.filteredDataSource = this.dataSource.filter(
          (item) => item.ExaminationCategory.name === e,
        )
      }
    },
    handleAddExamination() {
      this.$router.push('/laborant/add')
    },
    handleRowClick(record) {
      console.log(record)
      this.$router.push('/laborant/detail/' + record.id)
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>
